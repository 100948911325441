<template>
    <div>
		<b-modal ref="modal" size="lg" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t('invoice.preview_baf.title') }}
				</template>
			</template>

			<div>
				<h4>{{ $t('invoice.preview_baf.select_models') }}</h4>

				<div v-for="model in models" :key="model.accounting_plan.accountingplan_id" class="form-group">
					<label for="modeltype_label"> {{ model.accounting_plan.accountingplan_label }}</label>
					<e-select
						v-model="model_selected[model.accounting_plan.accountingplan_tiers]"
						id="model_id"
						track-by="model_label"
						label="model_label"
						:options="model.models"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
					>
					</e-select>
				</div>

				<div class="text-center">
                	<b-button  pill variant="primary" class="mt-3"  @click.prevent="submit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.previsualiser") }}</b-button>
            	</div>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">

	export default {
		name: "ModalSelectModelPreviewBAF",
		mixins: [],
		props: ['models', 'callback'],
		data () {
			return {
				model_selected: [],
				processing: false
			}
		},
		methods: {
			openModal() {
				this.$refs.modal.show()
			},
			closeModal() {
				this.processing = false
				this.$refs.modal.hide()
			},
			submit() {
				this.processing = true
				this.callback(this.model_selected)
			}
		},
		watch: {
			models(val) {
				val.forEach(model => {
					this.model_selected[model.accounting_plan.accountingplan_tiers] = model.models.find(m => m.model_default)
				})
			}
		}
	}
</script>